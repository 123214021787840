.App-header {
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-size: calc(2vmin);
    color: white;
  }
  
  .App-logo {
    text-decoration: none;
    color: inherit; /* ロゴの色を継承 */
  }
  
  .App-logo h1 {
    margin: 0;
  }
  