.nav-list {
    list-style: none; /* 箇条書きの点を排除 */
    padding: 0;
    margin: 0;
    display: flex; /* 横に並べる */
    align-items: center; /* ナビゲーション項目を中央に揃える */
  }
  
  .nav-item {
    margin: 0 10px;
  }
  
  .nav-item a {
    color: white; /* リンクの色を変更 */
    text-decoration: none;
    padding: 4px 8px; /* パディングを小さく */
    border-radius: 4px;
    transition: background-color 0.3s;
    font-size: 14px; /* フォントサイズを小さく */
  }
  
  .nav-item a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  