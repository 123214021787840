.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header, .App-footer {
  flex-shrink: 0;
}

.App-main {
  flex-grow: 1;
}

/* 既存のスタイルに加えて、CookieConsent用のスタイルを追加 */
.CookieConsent {
  background: #2B373B;
  color: white;
}

.CookieConsent button {
  color: #4e503b;
  font-size: 13px;
}

.CookieConsent a {
  color: #61dafb;
  text-decoration: none;
}
